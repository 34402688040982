import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './calendar.module.scss';
import { format, addHours, startOfDay, isSaturday, isSunday, isTomorrow, isFriday, isMonday, isSameDay } from 'date-fns'
import { pl } from 'date-fns/locale'
import cn from 'classnames';
import { createPortal } from 'react-dom';
import {ReactComponent as Smile} from './smile.svg';

type CalendarDayCardProps = {
  ordersCount?: number;
  active: boolean;
  disabled: boolean;
  day: Date;
  action?(dat: Date): void;
  timeToOrder: number;
  style: 'normal' | 'xmas';
  onScroll: () => void;
}

const isDisabled = (day: Date, timeToOrder: number): boolean => (addHours(startOfDay(day), -timeToOrder).getTime() < Date.now());
const isHoliday = (day: Date): boolean => {
  return [
    new Date(new Date().getFullYear(), 10, 1),
    new Date(new Date().getFullYear(), 10, 11),
    new Date(new Date().getFullYear(), 10, 12),
    new Date(2022, 3, 16), // Wielkanoc
    new Date(2022, 3, 17), // Wielkanoc
    new Date(2022, 3, 18), // Wielkanoc
    new Date(new Date().getFullYear(), 4, 1),
    new Date(new Date().getFullYear(), 4, 2),
    new Date(new Date().getFullYear(), 4, 3),
    new Date(new Date().getFullYear(), 5, 16),
    new Date(new Date().getFullYear(), 5, 17),
    // trzech króli
    new Date(new Date().getFullYear(), 0, 6),
    new Date(new Date().getFullYear(), 0, 7),
    new Date(new Date().getFullYear() + 1, 0, 6),
    new Date(new Date().getFullYear() + 1, 0, 7),
    // xMas
    new Date(new Date().getFullYear(), 11, 25),
    new Date(new Date().getFullYear(), 11, 26),
    new Date(new Date().getFullYear(), 11, 27),
    new Date(new Date().getFullYear(), 11, 28),
    new Date(new Date().getFullYear(), 11, 29),
    new Date(new Date().getFullYear(), 11, 30),
    new Date(new Date().getFullYear(), 11, 31),
    // new year
    new Date(new Date().getFullYear(), 0, 1),
    new Date(new Date().getFullYear(), 0, 2),
    new Date(new Date().getFullYear()+1, 0, 1),
    new Date(new Date().getFullYear()+1, 0, 2),
    // 15 sierpnia
    new Date(new Date().getFullYear(), 7, 15),
  ].some(holiDay => isSameDay(holiDay, day));
}

const isBusinessClosed = (day: Date): boolean => {
  return [
    new Date(2024, 3, 27),
    new Date(2024, 3, 28),
    new Date(2024, 3, 29),
    new Date(2024, 3, 30),
    new Date(2024, 4, 1),
    new Date(2024, 4, 2),
    new Date(2024, 4, 3),
    new Date(2024, 4, 4),
    new Date(2024, 4, 5),
  ].some(holiDay => isSameDay(holiDay, day));
}

const isLongWeekend = (day: Date): boolean => {
  return [
    new Date(2024, 4, 30),
    new Date(2024, 4, 31),
  ].some(holiDay => isSameDay(holiDay, day))
}

const getPopupText = (day: Date, isCalculatedDisabled: boolean) => {
  const today = new Date();
  if(isLongWeekend(day)){
    return 'Przykro nam, dostawa Pobudki w długi weekend jest niemożliwa - odpoczywamy'
  }

  if(isBusinessClosed(day)){
    return 'Przykro nam, pyszna Pobudka nie jest dostępna tego dnia.'
  }

  if(isHoliday(day)){
    return 'Przykro nam, pyszna Pobudka w dni świąteczne jest niemożliwa.'
  }

  if (isSaturday(day) || isSunday(day)) {
    return 'Przykro nam, pyszna Pobudka w weekendy jest jeszcze niemożliwa.';
  }

  if (
    isCalculatedDisabled &&
    (isTomorrow(day) ||
      (
        (isFriday(today) || isSaturday(today) || isSunday(today))
        && (isMonday(day))
      )
    )) {
    return 'Niestety, zamówienie jest już niemożliwe. Zamawiaj posiłki na kolejny dzień do godz. 12:00.';
  }

  if (isCalculatedDisabled) {
    return <>Menu in progress <Smile /> </>;
  }
};

const CalendarDayCard = ({ ordersCount, day, active, action, disabled, timeToOrder, style, onScroll }: CalendarDayCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [scrollCompleted, setScrollCompleted] = useState(false);
  useLayoutEffect(() => {
    if (active && ref.current) {
      (ref.current as any)?.scrollIntoViewIfNeeded(true);
      setTimeout(() => setScrollCompleted(true), 1500); // 1500 just because.
    }
  }, [active]);

  // ACHTUNG!!!11!!1 we need to call onScroll in useEffect because otherwise the reference in of container (in Calendar component) is not set!
  useEffect(() => {
    onScroll();
  }, [scrollCompleted, onScroll]);

  const [hover, setHover] = useState(false);

  const rect = ref.current?.getBoundingClientRect() || { top: 0, left: 0 };

  const selectDay = (day: Date) => {
    if (action) action(day);
  }
  const isCalculatedDisabled = isDisabled(day, timeToOrder) || disabled;
  const classNames = cn(
    styles.dayCard,
    {
      [styles.disabledCard]: isCalculatedDisabled,
      [styles.activeCard]: active,
      [styles.xMas]: style === 'xmas'
    }
  )

  const isMobile = window.matchMedia?.('(max-width: 768px)').matches || false;

  const popupText = getPopupText(day, isCalculatedDisabled);

  return (
    <div className={classNames} onClick={() => selectDay(day)} title={format(day, 'EEEE, P', { locale: pl })} ref={ref}>
      <span>{format(day, 'iiiiii', { locale: pl })}</span>
      <big>{day.getDate()}</big>
      <small>{format(day, 'LLL', { locale: pl })}</small>
      {!!ordersCount && <div className={styles.countBadge}>{ordersCount}</div>}
      {isCalculatedDisabled && <div className={styles.overlay} onClick={(e) => e.stopPropagation()} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}></div>}
      {
        hover && createPortal(
          <div className={cn(styles.priceTooltip)} style={{ top: rect.top + 80, left: isMobile ? 0 : rect.left, '--element-pointer-x': isMobile ? `${rect.left + 24}px` : '2rm' } as any}>
            {popupText}
          </div>,
          document.getElementById('root')!
        )
      }
    </div>
  )
}
export default CalendarDayCard;
